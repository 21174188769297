<script lang="ts" setup>
import type { BreadcrumbItem, PageType } from "~~/models/breadcrumb";
import type { PageRecord } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ page?: any; pageType?: PageType }>();

const localePath = useLocalePath();
const { t } = useI18n();

const breadcrumbs = ref([] as Array<BreadcrumbItem>);
breadcrumbs.value.push({ title: t("breadcrumb.home"), link: "/" });

if (props.page) {
  if (props.pageType) {
    const layout = await useLayout();

    let parentPage: PageRecord | null | undefined = null;

    switch (props.pageType) {
      case "events":
        parentPage = layout.value?.parentPageEvents as PageRecord;
        break;
      case "news":
        parentPage = layout.value?.parentPageNews as PageRecord;
        break;
      case "stores":
        parentPage = layout.value?.parentPageStores as PageRecord;
        break;
      case "trends":
        parentPage = layout.value?.parentPageTrends as PageRecord;
        break;
    }

    if (parentPage) {
      breadcrumbs.value.push({
        title: parentPage?.title,
        link: localePath(`/${parentPage?.slug}`),
      });
    }
  }

  if (props.page.parentPage) {
    breadcrumbs.value.push({
      title: props.page.parentPage?.title,
      link: localePath(`/${props.page.parentPage?.slug}`),
    });
  }

  if ((props.page as PageRecord).title !== t("breadcrumb.home")) {
    // stores has property "name", others have "title"
    breadcrumbs.value.push({ title: props.page.title ?? props.page.name });
  }
}
</script>

<template>
  <nav v-if="breadcrumbs.length > 1" v-bind="$attrs" class="text-xs font-medium leading-5" aria-label="Breadcrumbs">
    <ul class="flex flex-wrap">
      <Breadcrumb v-for="item in breadcrumbs" :key="item.link" :item="item" />
    </ul>
  </nav>
</template>

<style scoped>

</style>
